function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
}

// Referrer / affiliate tracking
const referrer = getUrlParameter('ref');
if (typeof referrer !== 'undefined') {
    Cookies.set('discount', referrer, { domain: '.patma.co.uk', expires: 360, secure: true });
}

// Try to record where people are coming from
const src = Cookies.get('src');
if (src === undefined) {
    let new_hist = "";
    new_hist += "r=" + document.referrer;
    new_hist += "&d=" + (new Date()).toISOString();
    const utmTags = window.location.search.match(/utm_(\w+)=([^&]+)/gi);
    if (utmTags) {
        for (const utmTag of utmTags) {
            new_hist += "&" + utmTag;
        }
    }
    Cookies.set('src', new_hist, { domain: '.patma.co.uk', expires: 180, secure: true });
}

// Pricing toggle
const toggleClass = "d-none";
const tableWrapper = document.querySelector(".pricing-table");
const switchInputs = document.querySelectorAll(".switch-wrapper input");
if (tableWrapper && switchInputs) {
    const prices = tableWrapper.querySelectorAll(".price");

    for (const switchInput of switchInputs) {
        switchInput.addEventListener("input", function () {
            for (const price of prices) {
                price.classList.add(toggleClass);
            }
            const activePrices = tableWrapper.querySelectorAll(
                `.price.${switchInput.id}`
            );
            for (const activePrice of activePrices) {
                activePrice.classList.remove(toggleClass);
            }
        });
    }
}

var rotators = document.getElementsByClassName("text-rotator");
for (var i = 0; i < rotators.length; i++) {
    new QubasTextRotatorTurbo(rotators[i]);
}
